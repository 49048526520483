@tailwind base;
@tailwind components;
@tailwind utilities;


@supports (padding-top: env(safe-area-inset-top)) {
  body {
    padding: 0;
    height: calc(100% + env(safe-area-inset-top));
  }
}

body {
  background: black;
  overflow: hidden;
  margin: 0;

  font-family: monospace;
  font-size: 2em;
  text-align: center;
}

canvas {
  width: 100vw;
  height: 100vh;
}

p {
  color: hsl(108, 90%, 70%);
  text-shadow: hsl(108, 90%, 40%) 1px 0 10px;
}

.notice {
  margin-top: 10em;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pill {
  display: inline-block;
  background: gray;
  border: 0.3em solid lightgray;
  font-size: 1rem;
  font-family: monospace;
  color: white;
  padding: 0.5em 1em;
  border-radius: 2em;
  min-width: 6rem;
  margin: 3em;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}

.blue {
  background: linear-gradient(skyblue, blue, black, black, darkblue);
  border-color: darkblue;
  color: lightblue;
}

.blue:hover {
  border-color: blue;
  color: white;
}

.red {
  background: linear-gradient(lightpink, crimson, black, black, darkred);
  border-color: darkred;
  color: lightpink;
}

.red:hover {
  border-color: crimson;
  color: white;
} 

.text-shadow-border {
  text-shadow:
      1px 1px 1px black,
      -1px -1px 1px black,
      1px -1px 1px black,
      -1px 1px 1px black;
}